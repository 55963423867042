import { createStyles, Text, Container, ActionIcon, Group, Title } from '@mantine/core';
import footerImg from "./footerImg.jpeg";

const useStyles = createStyles((theme) => ({
  footer: {
    paddingTop: theme.spacing.xl * 2,
    paddingBottom: theme.spacing.xl * 2,
    backgroundImage: `url(${footerImg})`,
    backgroundSize: "cover"

  },

  logo: {
    maxWidth: 200,

    [theme.fn.smallerThan('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },

  facebookLogo: {
    maxWidth: 500,

    [theme.fn.smallerThan('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },

  description: {
    marginTop: 15,

    [theme.fn.smallerThan('sm')]: {
      marginTop: theme.spacing.xs,
      textAlign: 'center',
    },
  },

  inner: {
    display: 'flex',
    flexDirection: 'row',
    flexBasis: 'auto',
    justifyContent: 'space-between',

    [theme.fn.smallerThan('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },

  groups: {
    display: 'flex',
    flexWrap: 'wrap',

    [theme.fn.smallerThan('sm')]: {
      display: 'none',
    },
  },

  wrapper: {
    width: 160,
  },

  title: {
    marginTop: 15,
    fontSize: theme.fontSizes.lg,
    fontWeight: 700,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    marginBottom: theme.spacing.xs / 2,
    color: 'white',
  },

  afterFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing.xl,
    paddingTop: theme.spacing.xl,
    paddingBottom: theme.spacing.xl,
    borderTop: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2]
    }`,

    [theme.fn.smallerThan('sm')]: {
      flexDirection: 'column',
    },
  },

  fbPage: {
    [theme.fn.smallerThan('sm')]: {
        marginTop: 20
      }
  },

  social: {
    [theme.fn.smallerThan('sm')]: {
      marginTop: theme.spacing.xs,
    },
  },
}));

export function FooterLinks() {
  const { classes } = useStyles();

  return (
    <footer className={classes.footer}>
      <Container className={classes.inner}>
        <div className={classes.logo}>
          <Title className={classes.title}>SC Nord Est Assistance</Title>
          <Text size="xs" color="dimmed" className={classes.description}>
            Servicii auto calitative, eficiente si sigure, 24/7.
          </Text>
        </div>
        <div className={classes.fbPage}>
            <div style={{width: '100%'}} className="fb-page" data-href="https://www.facebook.com/profile.php?id=100063743941224" data-tabs="" data-width="400" data-height="" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="false"><blockquote cite="https://www.facebook.com/profile.php?id=100063743941224" className="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/profile.php?id=100063743941224">Tractari Office</a></blockquote></div>
        </div>      
      </Container>
      <Container className={classes.afterFooter}>
        <Text color="dimmed" size="sm">
          © 2022 SC Nord Est Assistance SRL. Toate drepturile rezervate.
        </Text>
        <Text color="dimmed" size="sm">
          Made by: <a style={{color: 'white'}} href="https://muresanrazv.github.io/PersonalWebsite/">Muresan Razvan</a>
        </Text>
      </Container>
    </footer>
  );
}