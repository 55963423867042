import { Carousel } from '@mantine/carousel';
import { createStyles, Title, Card, Image, Group, Avatar, Text, ActionIcon, } from '@mantine/core';
import { useState, useEffect } from 'react';
import { IconThumbUp } from '@tabler/icons';
import axios from 'axios';
import Moment from 'moment';

const useStyles = createStyles((theme) => ({
    title: {
        fontWeight: 750,
        position: 'relative',
        textAlign: 'left',
        zIndex: 2,
        paddingLeft: 16,
        [theme.fn.largerThan('sm')]: {
            paddingLeft: 64,
          }
    },

    card: {
      backgroundColor: "#393e41",
      
    },
  
    footer: {
      padding: `${theme.spacing.xs}px ${theme.spacing.lg}px`,
      marginTop: theme.spacing.md,
    },

    overlay: {
      position: 'absolute',
      height: 60,
      width: 150,
      left: 0,
      backgroundColor: '#f0dfa1',
      zIndex: 1
    },

    carousel: {
      height: 400
    },

    text: {
      fontSize: 15,
      [theme.fn.smallerThan('sm')]: {
        fontSize: 12,
      },
    }
}))

interface PostsProps {
  id: string,
  created_time: string,
  full_picture: string,
  likes: number,
  permalink_url: string,
  status_type: string,
  story: string
}

export function NewsCarousel() {
  const { classes } = useStyles();
  const [profilePic, setProfilePic] = useState([]);
  const [posts, setPosts] = useState<PostsProps[]>([]);

  useEffect(() => {  
    axios.get("https://graph.facebook.com/v15.0/598722126918284/feed?fields=permalink_url,created_time,full_picture,story,status_type&access_token=EAAL0ksU2VRIBABIgX6g9yVqKvbkHf7huvVsigyFgHwKKKWgcwDX2ZCF9PW5TPdRGivp6CSpSrdSTfQhEvNUSeIJuMf70LV698ijHjG8VvVuEUkUQBFHaBv6wHvOPO8xSb53vn4H2KGtmCUQktI78z28EvStoJQaopDQujMTQmgSJb4UMC")
      .then((response) => {
        return response.data.data;
      })
      .then(async (response) => {
        for (let i = 0; i < response.length; i++)
        {
          if (!response[i].story)
                response[i].story = "Tractari Office a adăugat o postare.";        
          };
        setPosts(response);
      });

    axios.get("https://graph.facebook.com/v14.0/598722126918284/picture?redirect=0")
      .then((response) => {
        setProfilePic(response.data.data.url);
      })
   }, []);

  const mapLinks = posts.filter(post => post.status_type === 'added_photos').map((currentMedia: PostsProps) => (
    <Carousel.Slide>
        <Card withBorder p="md" radius="md" className={classes.card}>
      <Card.Section mb="sm">
        <Image src={currentMedia.full_picture} height={270} />  
      </Card.Section>

      <Group mt="md">
        <Avatar src={profilePic.toString()} radius="sm" />
        <Text weight={400} color='white' className={classes.text}>{currentMedia.story}</Text>
      </Group>

      <Card.Section className={classes.footer}>
        <Group position="apart">
          <Text size="xs" color="dimmed">
            {Moment(currentMedia.created_time).format("D MMM YY")}
          </Text>
          <Group spacing={0}>
            <ActionIcon component='a' href={currentMedia.permalink_url}>
              <IconThumbUp size={18} color='#1864AB' stroke={1.5} />
            </ActionIcon>
            {currentMedia.likes}
          </Group>
        </Group>
      </Card.Section>
    </Card>
    </Carousel.Slide>
  ));

  return (
    <div style={{paddingBottom: 50}}>
        <div style={{paddingTop: 90, paddingBottom: 30}}>
        <div className={classes.overlay} />
        <Title order={1} className={classes.title}>Noutati</Title>
        </div>
        
        <Carousel
        controlSize={40}
        height={400}
        slideSize="33.33%"
        slideGap={1}
        breakpoints={[
          { maxWidth: 'md', slideSize: '50%' },
          { maxWidth: 'sm', slideSize: '100%', slideGap: 0 },
        ]}
        loop
        align="start"
        className={classes.carousel}
        >
        {mapLinks}   
        <Carousel.Slide></Carousel.Slide>
        </Carousel>
    </div>
  );
}