import { createStyles, Text, SimpleGrid, Container, Title } from '@mantine/core';
import { IconTruck, IconClock, IconChecklist, TablerIcon, IconCar, IconMotorbike, IconMan, IconExchange, IconTruckLoading, IconTool } from '@tabler/icons';
import { FeaturesImages } from './otherServices';

const useStyles = createStyles((theme) => ({
  feature: {
    position: 'relative',
  },

  overlay: {
    position: 'absolute',
    height: 60,
    width: 150,
    left: 0,
    backgroundColor: '#f0dfa1',
    zIndex: 1
  },

  content: {
    position: 'relative',
  },

  icon: {
    color: 'black',
  },

  title: {
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,
  },

  sectionTitle: {
    position: 'relative',
    textAlign: 'left',
    fontWeight: 750,
    paddingBottom: 50,
    zIndex: 2
  },

  container: {
    backgroundColor: "#d3d0cb",
    paddingTop: 50
  },

  carsTitle: {
    
  },

  carsDiv: {
    marginTop: 60,
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 200,
  },
}));

interface FeatureProps extends React.ComponentPropsWithoutRef<'div'> {
  icon: TablerIcon;
  title: string;
  description: string;
}

function Feature({ icon: Icon, title, description, className, ...others }: FeatureProps) {
  const { classes, cx } = useStyles();

  return (
    <div className={cx(classes.feature, className)} {...others}>
      <div className={classes.content}>
        <Icon size={38} className={classes.icon} stroke={1.5} />
        <Text weight={700} size="lg" mb="xs" mt={5} className={classes.title}>
          {title}
        </Text>
        <Text color="black" size="sm" weight={600}>
          {description}
        </Text>
      </div>
    </div>
  );
}

const mockdata = [
  {
    icon: IconTruck,
    title: 'Servicii',
    description:
      'Oferim o gama variata de servicii in domeniul auto: 𝗧𝗿𝗮𝗰𝘁𝗮𝗿𝗶, 𝗥𝗲𝗺𝗼𝗿𝗰𝗮𝗿𝗶, 𝗗𝗲𝗯𝗹𝗼𝗰𝗮𝗿𝗶, 𝗗𝗲𝗽𝗮𝗻𝗮𝗿𝗶, 𝗝𝘂𝗺𝗽 𝗦𝘁𝗮𝗿𝘁, 𝗣𝗮𝗿𝗰𝗮𝗿𝗲 𝗦𝗲𝗰𝘂𝗿𝗶𝘇𝗮𝘁𝗮',
  },
  {
    icon: IconClock,
    title: 'Serviciu 24/7',
    description:
      'SC Nord Est Assistance SRL va ofera serviciu NON-STOP in fiecare zi a saptamanii. Va asiguram o interventie sigura si rapida.',
  },
  {
    icon: IconChecklist,
    title: 'Experienta',
    description:
      'Dispunem de un personal foarte bine pregatit cu o experienta de mai bine de 9 ani in domeniul tractarilor auto.',
  },
];

export function FeaturesAsymmetrical() {
  const items = mockdata.map((item) => <Feature {...item} key={item.title} />);
  const { classes, cx } = useStyles();
    
  return (
  <div className={classes.container}>
    <Container pt={30} pb={30} size="lg">
      <div>
      <div className={classes.overlay} />
      <Title className={classes.sectionTitle}>Despre noi</Title>
      </div>
      <SimpleGrid cols={3} breakpoints={[{ maxWidth: 'sm', cols: 1 }]} spacing={50}>
        {items}
      </SimpleGrid>
    </Container>
    {FeaturesImages(
      {
        "supTitle": "Alte Servicii",
        "description": "",
        "data": [
          {
            "image": IconCar,
            "title": "Piese Auto"
          },
          {
            "image": IconMotorbike,
            "title": "Piese Moto"
          },
          {
            "image": IconTruck,
            "title": "Piese Autocamioane"
          },
          {
            "image": IconMan,
            "title": "Servicii de transport pasageri"
          },
          {
            "image": IconExchange,
            "title": "Masina la schimb"
          },
          {
            "image": IconTruckLoading,
            "title": "Transport auto pe platforma"
          },
          {
            "image": IconTool,
            "title": "Vulcanizare mobila"
          }

        ]
      }
      
    )}
  </div>
  );
}