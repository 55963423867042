import { Image, Text, Container, ThemeIcon, Title, SimpleGrid, createStyles } from '@mantine/core';
import { TablerIcon } from '@tabler/icons';
import ravenol from './ravenol.jpg';
import pointerSystems from './pointer.png';
import copleserv from './copleserv.png'

const useStyles = createStyles((theme) => ({
  wrapper: {
    paddingTop: 80,
    paddingBottom: 50,
  },

  item: {
    display: 'flex',
  },

  itemIcon: {
    padding: theme.spacing.xs,
    marginRight: theme.spacing.md,
  },

  itemTitle: {
    marginBottom: theme.spacing.xs / 2,
  },

  supTitle: {
    textAlign: 'center',
    textTransform: 'uppercase',
    fontWeight: 800,
    fontSize: theme.fontSizes.sm,
    color: "black", 
    letterSpacing: 0.5,
  },

  title: {
    lineHeight: 1,
    textAlign: 'center',
    marginTop: theme.spacing.xl,
  },

  description: {
    textAlign: 'center',
    marginTop: theme.spacing.xs,
  },

  highlight: {
    backgroundColor: "#393e41",
    padding: 5,
    paddingTop: 0,
    borderRadius: theme.radius.sm,
    display: 'inline-block',
    color: "white",
  },
}));

interface FeatureImage {
  image: TablerIcon;
  title: React.ReactNode;
}

interface FeaturesImagesProps {
  supTitle: React.ReactNode;
  description: React.ReactNode;
  data: FeatureImage[];
}

export function FeaturesImages({ supTitle, description, data }: FeaturesImagesProps) {
  const { classes } = useStyles();

  const items = data.map((item) => (
    <div className={classes.item}>
      <ThemeIcon color={"#393e41"} variant="light" className={classes.itemIcon} size={60} radius="md">
        <item.image size={40} color='white'/>
      </ThemeIcon>

      <div>
        <Text weight={700} size="lg" className={classes.itemTitle}>
          {item.title}
        </Text>
      </div>
    </div>
  ));

  return (
    <>
    <Container size={700} className={classes.wrapper}>
      <Text className={classes.supTitle}>{supTitle}</Text>

      <Title className={classes.title} order={2}>
        Tractari Office ofera mai <span className={classes.highlight}>multe</span> !
      </Title>

      <Container size={660} p={0}>
        <Text color="black" className={classes.description}>
          {description}
        </Text>
      </Container>

      <SimpleGrid
        cols={3}
        spacing={50}
        breakpoints={[{ maxWidth: 550, cols: 1, spacing: 40 }]}
        style={{ marginTop: 30 }}
      >
        {items}
      </SimpleGrid>
    </Container>
    <Container size={700} pt={80}>
      <Text className={classes.supTitle}>In parteneriat cu:</Text>

      <SimpleGrid
      cols={2}
      spacing={40}
      style={{marginTop: 30}}
      breakpoints={[{ maxWidth: 550, cols: 1, spacing: 40 }]}>
        <img src={ravenol} style={{marginLeft: 'auto', marginRight: 'auto'}}></img>
        <img src={pointerSystems} style={{marginLeft: 'auto', marginRight: 'auto'}}></img>
      </SimpleGrid>
      <SimpleGrid
      cols={1}>
        <img src={copleserv} style={{marginLeft: 'auto', marginRight: 'auto', maxWidth: 340}}></img>
      </SimpleGrid>
    </Container>
    </>
  );
}