import React, { useState } from 'react';
import { HeroContentLeft } from './components/headerComponent/heroHeader';
import { HeaderMenu } from './components/headerComponent/header';
import { FeaturesAsymmetrical } from './components/aboutComponent/about';
import { ContactUs } from './components/contactComponent/contact';
import { Gallery } from './components/galleryComponents/gallery';
import { FooterLinks } from './components/footerComponent/footer';
import { NewsCarousel } from './components/newsComponent/news';
import { useRef } from 'react';
import { IconArrowUp } from '@tabler/icons';
import { ActionIcon } from '@mantine/core';
import './App.css';

function App() {
  const homeRef = useRef(null);
  const aboutRef = useRef(null);
  const newsRef = useRef(null);
  const contactRef = useRef(null);
  const galleryRef = useRef(null);

  // make back to top button appear when scrolling
  const [backToTop, scrolled] = useState(false);
  const appearOnScroll = () => {
    if (window.scrollY >= 90)
      scrolled(true);
    else
      scrolled(false);
  }

  window.addEventListener('scroll', appearOnScroll);

  return (
    <div className="App">
      <div id="header" ref={homeRef}>
        <>
        {HeaderMenu({
          "links": [
            {
              "link": aboutRef,
              "label": "Despre noi"
            },
            {
              "link": newsRef,
              "label": "Noutati"
            },
            {
              "link": contactRef,
              "label": "Contact"
            },
            {
              "link": galleryRef,
              "label": "Galerie"
            },
          ]
        })}
        {HeroContentLeft()}
        </>
      </div>

      <div id="about" ref={aboutRef}>
        {FeaturesAsymmetrical()}
      </div>

      <div id="news" ref={newsRef}>
        {NewsCarousel()}
      </div>

      <div id="contact" ref={contactRef}>
        {ContactUs()}
      </div>

      <div id="gallery" ref={galleryRef}>
        {Gallery()}
      </div>

      <div id={backToTop ? "backToTopScrolled": "backToTop"}>
        <ActionIcon size={36} onClick={() => window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth"
        })}>
          <IconArrowUp size={36} />
        </ActionIcon>
      </div>

      <div id='footer'>
        {FooterLinks()}
      </div>
    </div>
  );
}

export default App;
