import { Carousel, Embla } from '@mantine/carousel';

import img1 from "./galleryImg/img1.jpg";
import img2 from "./galleryImg/img2.jpg";
import img5 from "./galleryImg/img5.jpg";
import img6 from "./galleryImg/img6.jpg";
import img7 from "./galleryImg/img7.jpg";
import img8 from "./galleryImg/img8.jpg";
import img9 from "./galleryImg/img9.jpg";
import img10 from "./galleryImg/img10.jpg";
import img11 from "./galleryImg/img11.jpg";
import img12 from "./galleryImg/img12.jpg";
import img13 from "./galleryImg/img13.jpg";
import img14 from "./galleryImg/img14.jpg";
import img15 from "./galleryImg/img15.jpg";
import img16 from "./galleryImg/img16.jpg";
import img17 from "./galleryImg/img17.jpg";
import img18 from "./galleryImg/img18.jpg";
import img19 from "./galleryImg/img19.jpg";
import img20 from "./galleryImg/img20.jpg";
import img21 from "./galleryImg/img21.jpg";
import img22 from "./galleryImg/img22.jpg";
import img24 from "./galleryImg/img24.jpg";

export function GalleryCarousel() {
    
    const images=[img1, img2, img5, img6, img7, img8, img9, img10,
                  img11, img12, img13, img14, img15, img16, img17, img18, img19, img20,
                  img21, img22, img24];

    const slides = (images.map((image: any) => (
        <Carousel.Slide>
            <img src={image} height={450}></img>
        </Carousel.Slide>
    ))         
    );

    return (
    <Carousel
     slideSize="50%" 
     height={450} 
     align="start"
     dragFree 
     withControls={false}
     breakpoints={[
      { maxWidth: 'sm', slideSize: '100%', slideGap: 0 },
    ]}>
      {slides}
    </Carousel>
  );
}