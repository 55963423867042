import React from "react";
import { createStyles, Header, Menu, Group, Center, Burger, Container, ActionIcon, Button } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useState } from 'react';
import headerLogo from "./logoOffice.png";
import facebookLogo from "./contactIcons/facebookIcon.png";
import whatsappLogo from "./contactIcons/whatsappIcon.png";
import phoneLogo from "./contactIcons/phoneIcon.png";

const useStyles = createStyles((theme) => ({
  header: {
    position: "fixed",
    background: `rgba(0, 0, 0, 0)`,
    transition: '0.3s ease-in',
    borderBottom: 0
  },

  headerScroll: {
    position: "fixed",
    background: `rgba(57, 62, 65, 100)`,
    transition: '0.3s ease-out',
    borderBottom: 0
  },

  inner: {
    height: 80,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },

  links: {
    [theme.fn.smallerThan('sm')]: {
      display: 'none',
    },
  },

  burger: {
    [theme.fn.largerThan('sm')]: {
      display: 'none',
    },
  },

  shortcutsScroll: {
    [theme.fn.largerThan('sm')]: {
      display: 'none',
    },
    visibility: 'visible',
    transition: '0.3s ease-out',
    opacity: 1
  },

  shortcuts: {
    [theme.fn.largerThan('sm')]: {
      display: 'none',
    },
    visibility: 'hidden',
    transition: '0.3s ease-in',
    opacity: 0
  },

  linksGroup: {
    [theme.fn.largerThan('sm')]: {
      display: 'none',
    }
  },

  link: {
    display: 'block',
    lineHeight: 1,
    padding: '8px 12px',
    borderRadius: theme.radius.sm,
    textDecoration: 'none',
    color: "white",
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,

    '&:hover': {
      backgroundColor: "black",
    },
  },

  linkLabel: {
    marginRight: 5,
  },

  button: {
    [theme.fn.smallerThan('sm')]: {
      display: 'none',
    },
    background: `rgb(226,192,68)`,
    color: 'rgb(30, 32, 25)'
  }
}));

interface HeaderSearchProps {
  links: { link: any; label: string;}[];
}

export function HeaderMenu({ links }: HeaderSearchProps) {
  const [opened, { toggle }] = useDisclosure(false);
  const [isOpen, setOpen] = useState(false)
  const { classes } = useStyles();

  // change header color when scrolling
  const [color, setColor] = useState(false);
  const changeColor = () => {
    if (window.scrollY >= 90)
      setColor(true);
    else
      setColor(false);
  }

  window.addEventListener('scroll', changeColor)

  const dropdownItems = links.map((link) => (
    <Menu.Item onClick={() => (link.link.current.scrollIntoView({ behavior: 'smooth', block: 'start' }))}>
      {link.label}
    </Menu.Item>  
  ));

  const items = links.map((link) => {
    return (
      <Button 
      variant="gradient"
      gradient={{ deg: 133, from: 'orange', to: '#FFB562' }}
      className={classes.button} key={link.label} onClick={() => (link.link.current.scrollIntoView({ behavior: 'smooth', block: 'start' }))}>
      {link.label}
      </Button>
    );
  });

  return (
    <Header height={80} className={color ? classes.headerScroll: classes.header}>
      <Container>
          <div className={classes.inner}>
            <img src={headerLogo} width={170} height={70}></img>
            <Button.Group>
              {items}
            </Button.Group>
            <Group className={classes.linksGroup}>
              <div className={color ? classes.shortcutsScroll: classes.shortcuts}>
                <Group spacing={4} noWrap ml={10}>
                  <ActionIcon size="lg" component='a' href='tel:0743-972-755'>
                    <img src={phoneLogo} style={{width: 36, height: 36}}></img>
                  </ActionIcon>
                  <ActionIcon size="lg" component='a' href='https://www.facebook.com/profile.php?id=100063743941224'>
                    <img src={facebookLogo} style={{width: 36, height: 36}}></img>
                  </ActionIcon>
                  <ActionIcon size="lg" component='a' href='https://wa.me/400743972755'>
                    <img src={whatsappLogo} style={{width: 36, height: 36}}></img>
                  </ActionIcon>
                </Group>
              </div>
              <Menu width={300} shadow="md" opened={isOpen} onChange={setOpen}>
                <Menu.Target>
                  <Burger opened={isOpen} onClick={() => (setOpen(false))} size="sm" className={classes.burger} />
                </Menu.Target>
                <Menu.Dropdown>         
                  {dropdownItems}
                </Menu.Dropdown>
              </Menu>
            </Group>
          </div>
      </Container>
    </Header>
  );
}