import {
    createStyles,
    Text,
    Title,
    SimpleGrid,
    ThemeIcon,
    Box,
    Stack
  } from '@mantine/core';

import { IconAt, IconMapPin, IconPhone, IconSun } from '@tabler/icons';

  type ContactIconVariant = 'white' | 'gradient';

  const useStyles = createStyles((theme) => ({
    wrapper: {
        minHeight: 400,
        boxSizing: 'border-box',
        backgroundColor: "#393e41",
        borderRadius: theme.radius.md,
        padding: theme.spacing.md * 1.5,
  
        [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
            padding: theme.spacing.md * 1.5,
      },
    },
  
    title: {
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        color: theme.white,
        lineHeight: 1,
    },
  
    description: {
        color: 'rgb(30, 32, 25)',
        maxWidth: 400,
  
        [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
            maxWidth: '100%',
        },
    },
  
    social: {
        color: theme.white,
  
        '&:hover': {
            color: theme.colors[theme.primaryColor][1],
      },
    },
  
    control: {
        backgroundColor: theme.colors[theme.primaryColor][6],
    },

    contactsWrapper: {
        display: 'flex',
        alignItems: 'center',
        color: theme.white,
        margin: 10,
        marginTop: 20,
        backgroundColor: 'rgb(226,192,68)',
        borderRadius: 5,
        padding: 10
    },

    icon: {
        marginRight: theme.spacing.md,
        color: 'black', 
        backgroundColor: 'transparent',
    },

    map: {
        alignSelf: 'right',
        boxSizing: 'border-box',
        flex: 1,
        width: '100%',
      },
    
    googlemaps: {
        width: '100%',
        height: 500,
        borderRadius: 5,
        '@media (max-width: 600px)': {
        height: 500,
        }
    }
  }));
  
  interface ContactIconProps extends Omit<React.ComponentPropsWithoutRef<'div'>, 'title'> {
    icon: React.FC<any>;
    title: React.ReactNode;
    description: React.ReactNode;
    variant?: ContactIconVariant;
  }

  function ContactIcon({
    icon: Icon,
    title,
    description,
    variant = 'gradient',
    className,
    ...others
  }: ContactIconProps) {
    const { classes, cx } = useStyles();
    return (
      <div className={cx(classes.contactsWrapper, className)} {...others}>
        {variant === 'gradient' ? (
          <ThemeIcon size={40} radius="md" className={classes.icon}>
            <Icon size={34} color='rgb(30, 32, 25)'/>
          </ThemeIcon>
        ) : (
          <Box mr="md">
            <Icon size={24} />
          </Box>
        )}
  
        <div>
          <Text size="md" color='rgb(30, 32, 25)' align='left' weight={600}>
            {title}
          </Text>
          <Text className={classes.description} align='left'>{description}</Text>
        </div>
      </div>
    );
  }

  interface ContactIconsListProps {
    data?: ContactIconProps[];
    variant?: ContactIconVariant;
  };

  const MOCKDATA = [
    { title: 'Email', description: 'extremetuning_pn@yahoo.com', icon: IconAt },
    { title: 'Telefon', description: '+40 0743 972 755', icon: IconPhone },
    { title: 'Adresa', description: 'B-dul general dascalescu nr 365 Piatra Neamt, Romania', icon: IconMapPin },
    { title: 'Program de lucru', description: 'NON-STOP', icon: IconSun },
  ];

  export function ContactIconsList({ data = MOCKDATA, variant }: ContactIconsListProps) {
    const items = data.map((item, index) => <ContactIcon key={index} variant={variant} {...item} />);
    return <Stack>{items}</Stack>;
  }

  export function ContactUs() {
    const { classes } = useStyles();
  
    return (
      <div style={{paddingTop: 50}}>
      <div className={classes.wrapper}>
        <SimpleGrid cols={2} spacing={50} breakpoints={[{ maxWidth: 'sm', cols: 1 }]}>
          <div>
            <Title className={classes.title}>Contact</Title>
            <ContactIconsList />
          </div>
          <div className={classes.map}>
              <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d43609.83559680337!2d26.33600803125!3d46.910295!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47355507551efc9b%3A0x3e12ba4c5a49487!2sTRACTARI%20AUTO%20PIATRA%20NEAM%C8%9A%20Nord%20Est%20Assistance!5e0!3m2!1sen!2sro!4v1664998036876!5m2!1sen!2sro"
                  width="500"
                  frameBorder="0"
                  style={{ border: 0 }}
                  aria-hidden="false"
                  className={classes.googlemaps}
                />
            </div>  
        </SimpleGrid>
      </div>
      </div>
    );
  }