import { GalleryCarousel } from "./galleryCarousel";
import { createStyles, Title } from '@mantine/core';

const useStyles = createStyles((theme) => ({
    wrapper: {
        height: 600,
        paddingTop: 70
    },

    sectionTitle: {
        position: 'relative',
        textAlign: 'left',
        fontWeight: 750,
        paddingBottom: 50,
        zIndex: 2,
        paddingLeft: 16,
        [theme.fn.largerThan('sm')]: {
            paddingLeft: 64,
          }
      },

      overlay: {
        position: 'absolute',
        height: 60,
        width: 150,
        left: 0,
        backgroundColor: '#f0dfa1',
        zIndex: 1
      },
})
);

export function Gallery() {
    const { classes, cx } = useStyles();

    return (
        <div className={classes.wrapper}>
            <div className={classes.overlay} />
            <Title className={classes.sectionTitle}>Galerie</Title>
            {GalleryCarousel()}
        </div>
    );
}