import React from 'react';
import { ActionIcon, Group, createStyles, Overlay, Container, Title, Button, Text, SimpleGrid } from '@mantine/core';
import heroHeaderPic from "./pic1.jpg";
import facebookLogo from "./contactIcons/facebookIcon.png";
import whatsappLogo from "./contactIcons/whatsappIcon.png";
import phoneLogo from "./contactIcons/phoneIcon.png";

const useStyles = createStyles((theme) => ({
  hero: {
    position: 'relative',
    backgroundImage: `url(${heroHeaderPic})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: 700
  },

  container: {
    height: 450,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    paddingBottom: theme.spacing.xl * 6,
    zIndex: 1,
    position: 'relative',

    [theme.fn.smallerThan('sm')]: {
      paddingBottom: theme.spacing.xl * 3,
    },
  },

  title: {
    color: theme.white,
    fontSize: 60,
    fontWeight: 900,
    lineHeight: 1.1,

    [theme.fn.smallerThan('sm')]: {
      fontSize: 50,
      lineHeight: 1.2,
    },

    [theme.fn.smallerThan('xs')]: {
      fontSize: 40,
      lineHeight: 1.3,
      marginLeft: 'auto',
      marginRight: 'auto'
    },
  },

  social: {
    width: 34 * 2 + 4,
    margin: "auto",

    [theme.fn.smallerThan('sm')]: {
      marginLeft: 'auto',
      marginRight: 'auto'
    },
  },

  contact: {
    [theme.fn.smallerThan('sm')]: {
      marginLeft: 'auto',
      marginRight: 'auto'
    },
  },

  description: {
    color: theme.white,
    fontWeight: 500,
    maxWidth: '100%',

    [theme.fn.smallerThan('sm')]: {
      maxWidth: '100%',
      fontSize: theme.fontSizes.sm,
    },
  },

  control: {
    marginTop: theme.spacing.xl * 1.5,
    backgroundColor: "#587b7f",
    transition: "0.3s",

    "&:hover": {
      backgroundColor: "#e2c044"
    }
  },
}));

export function HeroContentLeft() {
  const { classes } = useStyles();

  return (
    <div className={classes.hero}>
      <Overlay
        gradient="linear-gradient(180deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, .65) 40%)"
        opacity={1}
        zIndex={0}
      />
      <Container className={classes.container}>
        <Title className={classes.title}>SC Nord Est Assistance SRL</Title>
        <Text className={classes.description} size="lg" mt="xl">
          Serviciu local de asistenta in judetul Neamt si tractari auto in zona judetelor din nord estul tarii.
        </Text>

        <SimpleGrid className={classes.contact}>
          <Button size="md" radius="xl" className={classes.control}
          onClick={() => (window.open('tel:0743-972-755', '_blank'))}>
            Suna Acum!
          </Button>

          <Group spacing={4} className={classes.social} noWrap ml={20} mr={20}>
            <ActionIcon size="lg" component='a' href='https://www.facebook.com/profile.php?id=100063743941224'>
            <img src={facebookLogo} style={{width: 42, height: 42}}></img>
            </ActionIcon>
            <ActionIcon size="lg" component='a' href='https://wa.me/400743972755'>
            <img src={whatsappLogo} style={{width: 42, height: 42}}></img>
            </ActionIcon>
          </Group>
        </SimpleGrid>
      </Container>
    </div>
  );
}